// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conduct-js": () => import("./../../../src/pages/conduct.js" /* webpackChunkName: "component---src-pages-conduct-js" */),
  "component---src-pages-conference-js": () => import("./../../../src/pages/conference.js" /* webpackChunkName: "component---src-pages-conference-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-sponsor-js": () => import("./../../../src/pages/sponsor.js" /* webpackChunkName: "component---src-pages-sponsor-js" */)
}

